const DATA = {
    name: 'Ashley Wright',
    intro: `A random developer who makes and helps with random things every now and then`,
    teamProjects: [
        {
            name: 'World of Wonder',
            description: `
            This mod is focused on adding biomes with a more mystical and magical flair; including the rare Dandelion Fields which features giant dandelions!
            `,
            logo: './assets/images/projects/worldofwonder/worldofwonder.png',
            link: 'https://www.curseforge.com/minecraft/mc-mods/world-of-wonder',
            images: [
                './assets/images/projects/worldofwonder/wow1.png',
                './assets/images/projects/worldofwonder/wow2.png',
                './assets/images/projects/worldofwonder/wow3.png'
            ]
        },
        {
            name: "Tempad",
            description: `
            This mod adds the Tempad, a small tablet device with the ability to save locations and teleport to them 
            from anywhere on the Sacred Timeline.
            `,
            logo: './assets/images/projects/tempad/tempad.gif',
            link: 'https://www.curseforge.com/minecraft/mc-mods/Tempad',
            images: [
                './assets/images/projects/tempad/tempad1.png',
                './assets/images/projects/tempad/tempad2.png',
                './assets/images/projects/tempad/tempad3.png'
            ]
        },
        {
            name: "Spirit",
            description: `
            Spirit is a mod inspired by the Soul Shards mod for Forge, allowing you to create a crystal to capture mob 
            souls to use in a soul cage to spawn duplicates of those mobs.
            `,
            logo: './assets/images/projects/spirit/spirit.png',
            link: 'https://www.curseforge.com/minecraft/mc-mods/spirit',
            images: [
                './assets/images/projects/spirit/spirit1.png',
                './assets/images/projects/spirit/spirit2.png',
                './assets/images/projects/spirit/spirit3.gif'
            ]
        },
        {
            name: "Chipped",
            description: `
            Chipped is a 1.16+ building mod that expands on the variety of blocks you can use in-game. The mod offers 
            over 2000 unique building blocks. From stained glass variations, prismarine, guilded blackstone, and much much more.
            `,
            logo: './assets/images/projects/chipped/chipped.gif',
            link: 'https://www.curseforge.com/minecraft/mc-mods/chipped',
            images: [
                './assets/images/projects/chipped/chipped1.png',
                './assets/images/projects/chipped/chipped2.png',
                './assets/images/projects/chipped/chipped3.png'
            ]
        },
        {
            name: "ZAWA: Evolved",
            description: `
            ZAWA is a mod that adds tamable animal mobs with realistic behavior, animations and care needs, with real-life 
            animal conservation and science information so players - young and old - can learn while they play.
            `,
            logo: './assets/images/projects/zawa/zawa.png',
            link: 'https://www.curseforge.com/minecraft/mc-mods/zawa-evolved',
            images: [
                './assets/images/projects/zawa/zawa1.png',
                './assets/images/projects/zawa/zawa2.png',
                './assets/images/projects/zawa/zawa3.png',
                './assets/images/projects/zawa/zawa4.png',
                './assets/images/projects/zawa/zawa5.png'
            ]
        }
    ],
    personalProjects: [
        {
            name: 'Witchery: Resurrected',
            description: `
            Witchery: Resurrected aims to recreate and improve the popular Witchery Minecraft mod in modern versions; 
            with a focus on customizability.
            `,
            logo: './assets/images/projects/witchery/witchery.png',
            link: 'https://witchery.msrandom.net/',
            images: [
                './assets/images/projects/witchery/witchery1.png',
                './assets/images/projects/witchery/witchery2.png',
                './assets/images/projects/witchery/witchery3.png'
            ]
        },
        {
            name: 'AWLC',
            description: `
            A static-typed LLVM-compiled language inspired by Rust, C++ and Kotlin using features like ownership, 
            operator overloading, RAII, compile-time reflection and template metaprogramming.
            `,
            logo: './assets/images/projects/awlc/awlc.png',
            link: 'https://github.com/MsRandom',
            images: [
                './assets/images/projects/awlc/awlc1.png',
                './assets/images/projects/awlc/awlc2.png'
            ]
        },
        {
            name: 'Pog Board',
            description: `
            A discord bot build on Discord.Net that has listens to image reactions and posts messages with enough 
            reactions to a channel with an up-to-date count of the reactions. The amount of reactions, channel, emote required and prefix are all configurable. 
            Made for the Artists' Refuge.
            `,
            logo: './assets/images/projects/pogboard/pogboard.png',
            link: 'https://discord.gg/5e3PekvKmS',
            images: [
                './assets/images/projects/pogboard/pogboard1.png',
                './assets/images/projects/pogboard/pogboard2.png'
            ]
        },
        {
            name: 'WillowWorks Engine',
            description: `
            Memory-safe chunk-based C++ voxel game engine that relies on RAII for cleanup. With EnTT ECS-based in-game 
            objects and instanced + batched chunks. Used Vulkan, VulkanMemoryAllocator, glm and glfw for rendering.
            `,
            logo: './assets/images/projects/willowworks/willowworks.png',
            link: 'https://github.com/MsRandom',
            images: []
        },
    ]
};

export default DATA;
